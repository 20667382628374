import React, { FC, useEffect, useState } from 'react';

import { Popover } from 'antd';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { AppStateType } from 'store/reducers';

import { PlayersFieldSelect } from '../PlayersFieldSelect';

export const SelectFieldItem: FC<any> = ({
  team,
  style,
  color,
  isActive,
  selectPlayer,
  activePlayerPos = [],
  invertedText = false,
  disabled = false,
  deactivated = false,
  replacedPlayerNumber,
  isSubstitutionSecondSelect = false,
  playersOnField = [],
  setIsSecondSubtitutionSelectOpen,
  popoverDisable = false,
}) => {
  const [open, setOpen] = useState(false);
  const [activePos = {}] = activePlayerPos;
  const activeColor = invertedText ? '#fff' : '#000';
  const { activeEvents } = useSelector(
    (state: AppStateType) => state.recorderReducerNew,
  );

  const handleOpenChange = (newOpen: boolean) => {
    if (!newOpen) {
      setOpen(newOpen);
      // setIsSecondSubtitutionSelectOpen(true);
    } else {
      setIsSecondSubtitutionSelectOpen(false);
    }
  };
  const hide = () => {
    setOpen(false);
    setIsSecondSubtitutionSelectOpen(false);
  };

  useEffect(() => {
    if (isSubstitutionSecondSelect) {
      setOpen(true);
      setIsSecondSubtitutionSelectOpen(true);
    }
  }, [isSubstitutionSecondSelect]);
  let excludedPlayersForSelect = playersOnField?.positions?.map(
    (pos: any) => pos.player_number,
  );
  const [substitutionEvents] = activeEvents.filter(
    (trGroup) => trGroup.name == 'Substitution',
  );
  const [redCardEvents] = activeEvents.filter(
    (trGroup) => trGroup.name == 'Red card',
  );
  // let allOutPlayers: [] = [];
  if (substitutionEvents && excludedPlayersForSelect) {
    // TODO hotfix =(
    // // @ts-expect-error
    // allOutPlayers = substitutionEvents.tracks.map((tr) => {
    //   const playerOutAtr = tr.attributes.find((atr) => atr.key == 'player_out');
    //   if (playerOutAtr) {
    //     const subsTeamAttr = tr.attributes.filter(
    //       (atr: any) => atr.key === 'team',
    //     )[0];
    //     if (subsTeamAttr) {
    //       const t =
    //         subsTeamAttr?.values[0].value.toString().toLowerCase() ===
    //         team.name.toLowerCase();
    //       if (t) {
    //         return Number(playerOutAtr.values[0].value);
    //       }
    //     }
    //   }
    //   return -1;
    // });
    // TODO hotfix =(
    // substitutionEvents.tracks.forEach((tr) => {
    //   const playerOutAtr = tr.attributes.find((atr) => atr.key == 'player_in');
    //   if (playerOutAtr) {
    //     const subsTeamAttr = tr.attributes.filter(
    //       (atr: any) => atr.key === 'team',
    //     )[0];
    //     if (subsTeamAttr) {
    //       const t =
    //         subsTeamAttr?.values[0].value.toString().toLowerCase() ===
    //         team.name.toLowerCase();
    //       if (t) {
    //         excludedPlayersForSelect.push(Number(playerOutAtr.values[0].value));
    //       }
    //     }
    //   }
    // });
    // excludedPlayersForSelect = excludedPlayersForSelect.filter(
    //   (pn: number) =>
    //     //@ts-expect-error
    //     // TODO hotfix =(
    //     allOutPlayers.indexOf(pn) == -1,
    // );
  }
  if (
    replacedPlayerNumber &&
    excludedPlayersForSelect &&
    replacedPlayerNumber != activePos.player_number
  ) {
    excludedPlayersForSelect.push(Number(replacedPlayerNumber));
    excludedPlayersForSelect = excludedPlayersForSelect.filter(
      (pn: number) => pn != activePos.player_number,
    );
  }
  if (redCardEvents && excludedPlayersForSelect) {
    redCardEvents.tracks.forEach((tr) => {
      const playerRKAtr = tr.attributes.find((atr) => atr.key == 'player');
      if (playerRKAtr) {
        excludedPlayersForSelect.push(playerRKAtr.values[0].value);
      }
    });
  }
  return (
    <Popover
      content={
        <PlayersFieldSelect
          close={hide}
          team={{ name: team.name, color }}
          invertedText={invertedText}
          selectPlayer={selectPlayer}
          exclude={excludedPlayersForSelect}
        />
      }
      trigger="click"
      open={!popoverDisable && open}
      onOpenChange={handleOpenChange}
      placement="bottom"
    >
      <div
        className={clsx({
          playersFieldItem: true,
          isActive,
          disabled,
          deactivated,
        })}
        onClick={(ev) => {
          if (disabled) {
            return;
          }
          ev.stopPropagation();
          if (isSubstitutionSecondSelect) {
            setOpen(true);
          } else {
            selectPlayer(
              team,
              replacedPlayerNumber || activePos.player_number,
              true,
            );
          }
        }}
        style={{
          ...style,
          color: activeColor,
          backgroundColor: color,
        }}
      >
        <div>{replacedPlayerNumber || activePos.player_number}</div>
      </div>
    </Popover>
  );
};
