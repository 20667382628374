/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
import { ThunkAction } from 'redux-thunk';

import * as API from 'api';
import {
  ADD_NEW_EDITED_EVENT,
  ADD_USER_POINT,
  COPY_TRACK_EVENT,
  EVENT_WINDOW_MODE,
  OBSERVE_RECORDING,
  REMOVE_EDITED_EVENT,
  REMOVE_TRACK,
  REMOVE_USER_POINT,
  SET_EVENT_COORDINATES_ACTION,
  SET_PLAYED_FRAME,
  UPDATE_ALL_ACTIVE_EVENTS,
  UPDATE_EDITED_EVENT,
} from 'constants/actionTypes';
import { MACRO_TIMEOUT } from 'constants/constants';
import { AppStateType } from 'store/reducers';
import { alertReducer } from 'store/reducers/alerts.reducer';
import { fastEventReducer } from 'store/reducers/fastEvent.reducer';
import { recorderReducerNew } from 'store/reducers/recorder.reducer';
import {
  ActiveEventType,
  EditedEventType,
  EventAttributeType,
  EventAttributeValueType,
  FastEventType,
  TaskEventsType,
  TrackType,
} from 'types';
import {
  filterAndIndex,
  getCurrentEditedEvent,
  validateEvent,
} from 'utils/functions';
import { extractDefaultAttr } from 'utils/storeHelpers';

import { GetPlayers } from './gamePlayers.action';
import {
  CutAndCloneByRedCard,
  CutAndCloneBySubstitution,
} from './placements.action';
import { SetPossessionsFromTaskActions } from './possession.actions';
import { SetIsPlaying } from './video.action';

export type RecorderActionTypes =
  | NewEditedEventType
  | RemoveEditedEventType
  | RemoveTrackType
  | AddUserPointType
  | RemoveUserPointType
  | ObserveRecordingProcessType
  | CopyTrackToEventActionType
  | UpdateEditedEventType
  | BaseUpdateActualEventsActionType
  | SetPlayedFrameType
  | EventWindowModeType
  | SetEventCoordinatesActionType;

type NewEditedEventType = {
  type: typeof ADD_NEW_EDITED_EVENT;
  payload: EditedEventType;
};
type RemoveEditedEventType = {
  type: typeof REMOVE_EDITED_EVENT;
  payload: any;
};
type UpdateEditedEventType = {
  type: typeof UPDATE_EDITED_EVENT;
  payload: any;
};
type RemoveTrackType = {
  type: typeof REMOVE_TRACK;
  payload: { activeEvents: Array<any> };
};
type ObserveRecordingProcessType = {
  type: typeof OBSERVE_RECORDING;
  payload: {
    activeEvents: Array<ActiveEventType>;
    editedEvents: Array<EditedEventType>;
  };
};
type CopyTrackToEventActionType = {
  type: typeof COPY_TRACK_EVENT;
  clickedTrack: TrackType;
  selectedEvent: number;
  selectedAttributeValues: any;
};
type AddUserPointType = {
  type: typeof ADD_USER_POINT;
  payload: { position: number; id: number };
};
type RemoveUserPointType = {
  type: typeof REMOVE_USER_POINT;
  payload: { pointId: number };
};
type BaseUpdateActualEventsActionType = {
  type: typeof UPDATE_ALL_ACTIVE_EVENTS;
  payload: any;
};
// USED
const actualAddNewEditedEvent = (evt: EditedEventType): NewEditedEventType => ({
  type: ADD_NEW_EDITED_EVENT,
  payload: evt,
});

// USED
export const baseUpdateActualEventsAction = (
  events: any,
): BaseUpdateActualEventsActionType => ({
  type: UPDATE_ALL_ACTIVE_EVENTS,
  payload: events,
});
const {
  addNewEditedEventAction,
  stopEditingOrRecordingEventAction,
  pushActiveEventAction,
  setPlayerActionToEdit,
  moveTrackToNewEvent,
} = recorderReducerNew.actions;
// USED ++
export const AddNewEditedEvent = (
  eventId: number,
  currentPosition: number | null,
  trackId: number | null = null,
  fast_event: string | null = null,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return async (dispatch, getState) => {
    if (trackId) {
      //  кликнули по треку события, забираем его из записанных треков
      const evts = getState().recorderReducer.activeEvents;
      const activeEvent = evts.filter((evt: any) => evt.id === eventId)[0];
      const track = activeEvent.tracks.filter(
        (t: TrackType) => t.id === trackId,
      )[0];
      const editedEvent: EditedEventType = {
        id: activeEvent.id,
        name: activeEvent.name,
        track: track,
      };
      dispatch(actualAddNewEditedEvent(editedEvent));
      dispatch(addNewEditedEventAction(editedEvent));
    } else {
      //  кликнули по новому событию, его еще не записывали в треки
      const taskEvents = getState().taskReducer.activeTask?.task_events;
      if (taskEvents) {
        const activeTaskEvent = taskEvents.filter(
          (evt: TaskEventsType) => evt.id === eventId,
        )[0];
        const actual = getState().recorderReducer.activeEvents.filter(
          (evt: any) => evt.id === eventId,
        )[0];
        let maxId = 1;
        if (actual) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unsafe-optional-chaining
          maxId = Math.max(...actual?.tracks.map((el: any) => el.id)) + 1;
          // TODO maxId - not in used?
        }
        const defaultAttributeValues = [];
        for (const attr of activeTaskEvent.event_attributes) {
          for (const val of attr.values) {
            if (val.is_default) {
              defaultAttributeValues.push({
                ...attr,
                values: [val],
              });
            }
          }
        }
        const editedEvent: EditedEventType = {
          id: activeTaskEvent.id,
          name: activeTaskEvent.name,
          track: {
            id: -1,
            isRecording: true,
            start: currentPosition,
            stop: currentPosition,
            coord_x: null,
            coord_y: null,
            attributes: defaultAttributeValues,
            fast_event: fast_event,
          },
        };
        dispatch(addNewEditedEventAction(editedEvent));
        dispatch(actualAddNewEditedEvent(editedEvent));
      }
    }
  };
};

export const createFromFastEventAction = (
  fastEventId: string,
  currentPosition?: number,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch, getState) => {
    const { activeTask } = getState().taskReducer;
    const poorPerformancePlayedSec =
      getState().videoReducer.poorPerformancePlayedSec;
    const fastEvent = activeTask?.task_fast_events.filter(
      (evt: FastEventType) => evt.id === fastEventId,
    )[0];
    if (!fastEvent) {
      return;
    }
    const newEditEvent: EditedEventType = {
      id: fastEvent.event_id, // event id
      name: fastEvent.event_name, // event name
      track: {
        id: -1,
        isRecording: true,
        start: currentPosition || poorPerformancePlayedSec,
        stop: currentPosition || poorPerformancePlayedSec,
        coord_x: null,
        coord_y: null,
        attributes: extractDefaultAttr(fastEvent.default_values),
        fast_event: fastEventId,
      },
    };
    dispatch(addNewEditedEventAction(newEditEvent));
  };
};

// USED
const updateEditedEvent = (editedEvents: any): UpdateEditedEventType => ({
  type: UPDATE_EDITED_EVENT,
  payload: editedEvents,
});

// //USED
// const removeEditedEvent = (
//   editedEvents: any,
//   activeEvents: any,
// ): RemoveEditedEventType => ({
//   type: REMOVE_EDITED_EVENT,
//   payload: {
//     editedEvents: editedEvents,
//     activeEvents: activeEvents,
//   },
// });

// USED
export const ObserveRecordingActualAction = (
  editedEvents: Array<EditedEventType>,
  actualEvents: Array<ActiveEventType>,
): ObserveRecordingProcessType => ({
  type: OBSERVE_RECORDING,
  payload: {
    editedEvents: editedEvents,
    activeEvents: actualEvents,
  },
});

// USED
export const SelectTrackForEditAction = (
  eventId: number,
  trackId: number,
): ThunkAction<Promise<void>, AppStateType, undefined, RecorderActionTypes> => {
  return async (dispatch, getState) => {
    const selectedActive = getState().recorderReducer.activeEvents.filter(
      (evt: any) => evt.id === eventId,
    )[0];
    const selectedTrack = selectedActive.tracks.filter(
      (track: any) => track.id === trackId,
    )[0];
    const newEditedEvent: EditedEventType = {
      id: selectedActive.id,
      name: selectedActive.name,
      track: selectedTrack,
    };
    // const check = currentEdited.filter(evt => evt.id === newEditedEvent.id);
    // if (check.length === 0) {
    //     currentEdited.push(newEditedEvent);
    dispatch(updateEditedEvent([newEditedEvent]));
    // dispatch(updateEditedEvent(currentEdited));
    // }
  };
};
const {
  setActiveEvents,
  removeTrackAction,
  setNewError,
  updateTrackAttributes,
  updateEditedTrackAttributes,
} = recorderReducerNew.actions;

// USED
export const UnselectTrackFoEditAction = (
  eventId: number,
): ThunkAction<Promise<void>, AppStateType, undefined, RecorderActionTypes> => {
  return async (dispatch, getState) => {
    const currentEdited = [...getState().recorderReducer.editedEvents];
    const targetEventIndex = filterAndIndex(currentEdited, eventId, 'id');
    if (targetEventIndex.index >= 0) {
      const newEdited = [
        ...currentEdited.slice(0, targetEventIndex.index),
        ...currentEdited.slice(targetEventIndex.index + 1),
      ];
      dispatch(updateEditedEvent(newEdited));
    }
  };
};

// USED
export const RemoveEditedEventAction = (
  currentFastEvent: any,
  currentPosition?: number | undefined,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch, getState) => {
    const activeTaskId = getState().taskReducer.activeTask?.id;
    const editedEvents = [...getState().recorderReducerNew.editedEvents];
    const poorPerformancePlayedSec =
      getState().videoReducer.poorPerformancePlayedSec;
    const eventId = currentFastEvent.event_id || currentFastEvent.eventId;
    const event = getCurrentEditedEvent(editedEvents, currentFastEvent);
    const { setShowCoordinatesModal } = fastEventReducer.actions;
    const { setNotificationAction } = alertReducer.actions;
    if (event) {
      let stop = event.track.stop;
      if (
        event.track.isRecording &&
        stop + MACRO_TIMEOUT / 1000 < poorPerformancePlayedSec
      ) {
        stop = poorPerformancePlayedSec;
      }
      if (
        event.track.isRecording &&
        currentPosition !== undefined &&
        stop + MACRO_TIMEOUT / 1000 < currentPosition
      ) {
        stop = currentPosition;
      }
      if (event.track) {
        let trackId = event.track.id;
        const isValid = validateEvent(event, getState().taskReducer.activeTask);
        let createdTrack;
        let updatedTracks;
        let editedTrack;
        if (trackId > 0 && isValid) {
          try {
            const response = await API.updateEvent(
              event.track.start || 0,
              stop || 0,
              event.track.attributes,
              eventId,
              activeTaskId,
              trackId,
              event.track.coord_x,
              event.track.coord_y,
              event.track.fast_event,
            );
            editedTrack = response.updated_main_event;
            updatedTracks = response.updated_other_events;
          } catch (e: any) {
            console.log('e', e.response);
            const errorText = e?.response?.data?.error;
            dispatch(
              setNewError({
                error: errorText || 'Error updating track. Check console.',
              }),
            );
            dispatch(
              stopEditingOrRecordingEventAction({
                eventId: eventId,
                currentPosition: stop,
                trackId: trackId,
              }),
            );
            return;
          }
        } else if (isValid) {
          let response;
          try {
            response = await API.saveNewEvent(
              event.track.start || 0,
              stop || 0,
              event.track.attributes,
              eventId,
              activeTaskId,
              event.track.coord_x,
              event.track.coord_y,
              event.track.fast_event,
            );
            if (response.created_event?.key == 'redcard') {
              dispatch(CutAndCloneByRedCard(response.created_event));
            }
            if (response.created_event?.key == 'secondyellowcard') {
              dispatch(CutAndCloneByRedCard(response.created_event));
            }
            if (response.created_event?.key == 'substitution') {
              dispatch(CutAndCloneBySubstitution(response.created_event));
            }
            dispatch(setShowCoordinatesModal(false));
          } catch (e) {
            dispatch(
              setNotificationAction({
                type: 'error',
                message: 'Error saving track. Check console.',
              }),
            );
            dispatch(
              removeTrackAction({
                eventId: eventId,
                trackId: trackId,
              }),
            );
            return;
          }
          trackId = response.created_event.id;
          createdTrack = response.created_event;
          if (
            createdTrack.attributes.length !== event.track.attributes.length
          ) {
            dispatch(
              updateEditedTrackAttributes({
                eventId: event.id,
                attributes: createdTrack.attributes,
              }),
            );
          }
          updatedTracks = response.updated_events;
        } else if (!isValid) {
          dispatch(
            setNewError({
              error: 'Error. Invalid Action',
            }),
          );
        }
        if (isValid && createdTrack) {
          if (eventId === createdTrack.event_id) {
            dispatch(
              stopEditingOrRecordingEventAction({
                eventId: eventId,
                currentPosition: stop,
                trackId: trackId,
              }),
            );
          } else {
            dispatch(
              moveTrackToNewEvent({
                eventId: eventId,
                trackId: trackId,
                stop: stop,
                eventName: createdTrack.name,
                targetEventId: createdTrack.event_id,
              }),
            );
          }
        }
        if (isValid && updatedTracks) {
          updatedTracks.forEach((updatedEvent: any) => {
            dispatch(
              updateTrackAttributes({
                eventId: updatedEvent.event_id,
                trackId: updatedEvent.id,
                attributes: updatedEvent.attributes,
              }),
            );
          });
        }
        if (isValid && editedTrack) {
          dispatch(
            stopEditingOrRecordingEventAction({
              eventId: eventId,
              currentPosition: stop,
              trackId: trackId,
            }),
          );
        }
      }
    }
  };
};

// USED +
export const ObserveRecordingAction = (
  eventId: number,
  trackId: number,
  stop: number,
  isRecording: boolean,
): ThunkAction<Promise<void>, AppStateType, undefined, RecorderActionTypes> => {
  return async (dispatch, getState) => {
    const activeEvents = [...getState().recorderReducer.activeEvents];
    const editedEvents = [...getState().recorderReducer.editedEvents];

    // Process ActiveEvents
    const needsRemoval = false;
    let overlapsNext = false;
    const fni = filterAndIndex(activeEvents, eventId, 'id');
    const elem = { ...fni.element, tracks: [...fni.element.tracks] };
    const activeEventTrack = filterAndIndex(
      fni.element.tracks,
      true,
      'isRecording',
    );
    let oldStop = activeEventTrack.element.stop;
    let updatedActiveEventTrack = {
      ...activeEventTrack.element,
      stop: stop,
      isRecording: isRecording,
    };
    // проверить, что текущий трек не уперся в следующий трек
    if (activeEventTrack.index >= 0) {
      // записываемый трек найден
      if (elem.tracks.length - 1 > activeEventTrack.index) {
        // если этот трек не последний
        if (elem.tracks[activeEventTrack.index + 1].start > stop) {
          oldStop = null;
        } else {
          // создаем новый объект, так как текущий не мутируется
          updatedActiveEventTrack = {
            ...activeEventTrack.element,
            isRecording: isRecording,
            stop: elem.tracks[activeEventTrack.index + 1].start,
          };
          overlapsNext = true;
          // needsRemoval = true;
        }
      } else {
        oldStop = null;
      }
      elem.tracks = [
        ...elem.tracks.slice(0, activeEventTrack.index),
        { ...updatedActiveEventTrack },
        ...elem.tracks.slice(activeEventTrack.index + 1),
      ];
    }

    let newActiveEvents = [...activeEvents];
    if (fni.index >= 0) {
      newActiveEvents = [
        ...activeEvents.slice(0, fni.index),
        { ...elem },
        ...activeEvents.slice(fni.index + 1),
      ];
    }

    // Process EditedEvents
    const editedEvent = filterAndIndex(editedEvents, eventId, 'id');
    const updatedEditedEvent = {
      ...editedEvent.element,
      track: {
        ...editedEvent.element.track,
        stop: overlapsNext ? oldStop : stop,
        isRecording: isRecording,
        attributes: [...editedEvent.element.track.attributes],
      },
    };
    let newEditedEvents;
    if (editedEvent.index >= 0) {
      newEditedEvents = [
        ...editedEvents.slice(0, editedEvent.index),
        { ...updatedEditedEvent },
        ...editedEvents.slice(editedEvent.index + 1),
      ];
    } else {
      newEditedEvents = [{ ...updatedEditedEvent }];
    }

    if (needsRemoval) {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const fni = filterAndIndex(activeEvents, eventId, 'id');
      if (fni.index >= 0) {
        newActiveEvents = [
          ...activeEvents.slice(0, fni.index),
          ...activeEvents.slice(fni.index + 1),
        ];
      }
    }
    dispatch(ObserveRecordingActualAction(newEditedEvents, newActiveEvents));
  };
};

// USED
export const CopyTrackToEventAction = (
  clickedTrack: TrackType,
  selectedEvent: TaskEventsType,
  selectedAttributeValues: any,
): ThunkAction<Promise<void>, AppStateType, undefined, RecorderActionTypes> => {
  return async (dispatch, getState) => {
    const activeTask = getState().taskReducer.activeTask;
    let newAttributes: any = [];
    for (const attrVal of Object.keys(selectedAttributeValues)) {
      const selectedAttribute = selectedEvent.event_attributes.filter(
        (evt) => evt.id === Number(attrVal.split('-')[0]),
      )[0];
      if (selectedAttribute.type !== 'MULTIPLE') {
        const values = selectedAttribute.values.filter(
          (v) => v.id === selectedAttributeValues[attrVal],
        );
        newAttributes = [
          ...newAttributes,
          { id: selectedAttribute.id, values: values },
        ];
      } else {
        const existing = filterAndIndex(
          newAttributes,
          selectedAttribute.id,
          'id',
        );
        const values = selectedAttribute.values.filter(
          (v) => v.id === selectedAttributeValues[attrVal],
        );
        if (existing.index >= 0) {
          newAttributes = [
            ...newAttributes.slice(0, existing.index),
            {
              id: existing.element.id,
              values: [...existing.element.values, { ...values[0] }],
            },
            ...newAttributes.slice(existing.index + 1),
          ];
        } else {
          newAttributes = [
            ...newAttributes,
            { id: selectedAttribute.id, values: [...values] },
          ];
        }
      }
    }
    const activeEvents = [...getState().recorderReducer.activeEvents];
    const fni = filterAndIndex(activeEvents, selectedEvent.id, 'id');
    // if (!validateEvent(fni, activeTask)) {
    //   return;
    // }
    const response = await API.saveNewEvent(
      // @ts-ignore
      clickedTrack.start,
      clickedTrack.stop,
      newAttributes,
      selectedEvent.id,
      activeTask?.id,
      clickedTrack.coord_x,
      clickedTrack.coord_y,
      clickedTrack.fast_event,
    );
    const newTrack = {
      start: response.created_event.started_at,
      stop: response.created_event.finished_at,
      id: response.created_event.id,
      isRecording: false,
      attributes: [...response.created_event.attributes],
    };

    let updatedActiveEvents;
    if (fni.index >= 0 && fni.element.tracks.length > 0) {
      const newTracks: TrackType[] = [...fni.element.tracks, { ...newTrack }];
      // @ts-ignore
      newTracks.sort((a, b) => {
        // @ts-ignore
        return a.start > b.start ? 1 : a.start < b.start ? -1 : 0;
      });
      const newActiveEvent = {
        ...fni.element,
        tracks: [...newTracks],
      };
      updatedActiveEvents = [
        ...activeEvents.slice(0, fni.index),
        { ...newActiveEvent },
        ...activeEvents.slice(fni.index + 1),
      ];
    } else {
      const actualEvent = getState().taskReducer.activeTask?.task_events.filter(
        (evt: any) => evt.id === selectedEvent.id,
      )[0];
      // newTrack.id = 1;
      const newActiveEvent = {
        id: selectedEvent.id,
        name: actualEvent?.name || '',
        tracks: [
          {
            ...newTrack,
            attributes: [...newTrack.attributes],
          },
        ],
      };
      updatedActiveEvents = [...activeEvents, { ...newActiveEvent }];
    }
    dispatch(ObserveRecordingActualAction([], updatedActiveEvents));
  };
};

// USED
export const RemoveTrackActionV2 = (
  trackId: number,
  eventId: number,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch, getState) => {
    const { activeTask } = getState().taskReducer;
    // console.log(trackId, eventId, activeTask);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    try {
      if (activeTask) {
        API.deleteEvent(activeTask.id, trackId).then();
      }
      dispatch(removeTrackAction({ eventId: eventId, trackId: trackId }));
    } catch (e) {
      console.log(e);
    }
  };
};

// USED
export const ReplaceEditedEventAttributeValueAction = (
  valueId: number,
  attributeId: number,
  eventId: number,
): ThunkAction<Promise<void>, AppStateType, undefined, RecorderActionTypes> => {
  return async (dispatch, getState) => {
    const editedEvents = [...getState().recorderReducer.editedEvents];
    const editedEvent = filterAndIndex(editedEvents, eventId, 'id');
    const attribute: EventAttributeType | undefined = getState()
      .taskReducer.activeTask?.task_events.filter(
        (evt: any) => evt.id === eventId,
      )[0]
      .event_attributes.filter((attr: any) => attr.id === attributeId)[0];
    const value: EventAttributeValueType | undefined = attribute?.values.filter(
      (val) => val.id === valueId,
    )[0];
    let newEditedEvent;
    if (editedEvent.index >= 0 && !!attribute) {
      const attrId = filterAndIndex(
        editedEvent.element.track?.attributes || [],
        attribute.id,
        'id',
      );
      const newAttribute = {
        id: attribute.id,
        name: attribute.name,
        type: attribute.type,
        values: [{ ...value }],
      };

      if (attrId.index >= 0) {
        newEditedEvent = {
          ...editedEvent.element,
          track: {
            ...editedEvent.element.track,
            attributes: [
              ...editedEvent.element.track.attributes.slice(0, attrId.index),
              { ...newAttribute },
              ...editedEvent.element.track.attributes.slice(attrId.index + 1),
            ],
          },
        };
      } else {
        newEditedEvent = {
          ...editedEvent.element,
          track: {
            ...editedEvent.element.track,
            attributes: [
              ...editedEvent.element.track.attributes,
              { ...newAttribute },
            ],
          },
        };
      }
    }
    const newEditedEvents = [
      ...editedEvents.slice(0, editedEvent.index),
      { ...newEditedEvent },
      ...editedEvents.slice(editedEvent.index + 1),
    ];
    const activeEvents = [...getState().recorderReducer.activeEvents];
    const activeEvent = filterAndIndex(activeEvents, eventId, 'id');
    let newActiveEvent;
    if (activeEvent.index >= 0 && !!attribute) {
      const activeTrack = filterAndIndex(
        activeEvent.element.tracks,
        newEditedEvent.track.id,
        'id',
      );
      const activeAttr = filterAndIndex(
        activeTrack.element.attributes,
        attributeId,
        'id',
      );
      const newAttribute = {
        id: attribute.id,
        name: attribute.name,
        type: attribute.type,
        values: [{ ...value }],
      };
      if (activeAttr.index >= 0) {
        newActiveEvent = {
          ...activeEvent.element,
          tracks: [
            ...activeEvent.element.tracks.slice(0, activeTrack.index),
            {
              ...activeTrack.element,
              attributes: [
                ...activeTrack.element.attributes.slice(0, activeAttr.index),
                { ...newAttribute },
                ...activeTrack.element.attributes.slice(activeAttr.index + 1),
              ],
            },
            ...activeEvent.element.tracks.slice(activeTrack.index + 1),
          ],
        };
      } else {
        newActiveEvent = {
          ...activeEvent.element,
          tracks: [
            ...activeEvent.element.tracks.slice(0, activeTrack.index),
            {
              ...activeTrack.element,
              attributes: [
                ...activeTrack.element.attributes,
                { ...newAttribute },
              ],
            },
            ...activeEvent.element.tracks.slice(activeTrack.index + 1),
          ],
        };
      }
    }
    const newActiveEvents = [
      ...activeEvents.slice(0, activeEvent.index),
      { ...newActiveEvent },
      ...activeEvents.slice(activeEvent.index + 1),
    ];
    dispatch(ObserveRecordingActualAction(newEditedEvents, newActiveEvents));
  };
};

// USED
export const SendUpdateRequest = (
  eventId: number,
  trackId: number,
): ThunkAction<Promise<void>, AppStateType, undefined, RecorderActionTypes> => {
  return async (dispatch, getState) => {
    const activeTaskId = getState().taskReducer.activeTask?.id;
    const activeEvent = getState().recorderReducerNew.activeEvents.filter(
      (evt: any) => evt.id === eventId,
    )[0];
    const track = activeEvent.tracks.filter(
      (trck: any) => trck.id === trackId,
    )[0];

    const apiTrackHolder: TrackType = {
      id: trackId,
      start: track.start,
      stop: track.stop,
      coord_x: track.coord_x,
      coord_y: track.coord_y,
      attributes: track.attributes,
      isRecording: false,
      fast_event: track.fast_event,
    };
    if (!validateEvent(activeEvent, getState().taskReducer.activeTask)) {
      return;
    }
    await API.updateEvent(
      // @ts-ignore
      apiTrackHolder.start,
      apiTrackHolder.stop,
      apiTrackHolder.attributes,
      activeEvent.id,
      activeTaskId,
      trackId,
      apiTrackHolder.coord_x,
      apiTrackHolder.coord_y,
      apiTrackHolder.fast_event,
    );
  };
};

// USED
export const AppendEditedEventAttributeValueAction = (
  valueId: number,
  attributeId: number,
  eventId: number,
): ThunkAction<Promise<void>, AppStateType, undefined, RecorderActionTypes> => {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return async (dispatch, getState) => {
    // добавляем значение для multiple атрибутов
    const editedEvents = [...getState().recorderReducer.editedEvents];
    const activeEvents: Array<ActiveEventType> = [
      ...getState().recorderReducer.activeEvents,
    ];
    const activeTask = getState().taskReducer.activeTask;
    const baseEventAttribute: EventAttributeType | undefined =
      activeTask?.task_events
        .filter((evt: any) => evt.id === eventId)[0]
        .event_attributes.filter((attr: any) => attr.id === attributeId)[0];
    const value: EventAttributeValueType | undefined =
      baseEventAttribute?.values.filter((val) => val.id === valueId)[0];

    const editedEvent = filterAndIndex(editedEvents, eventId, 'id');
    const editedAttributeFound = filterAndIndex(
      editedEvent.element.track.attributes,
      attributeId,
      'id',
    );
    const editedAttribute: EventAttributeType = editedAttributeFound.element;
    let newEditedEvent: EditedEventType | any = undefined;
    let newEditedEvents: EditedEventType[] | any = [];
    if (editedAttributeFound.index >= 0) {
      if (editedEvent.index >= 0 && typeof value !== 'undefined') {
        newEditedEvent = {
          ...editedEvent.element,
          track: {
            ...editedEvent.element.track,
            attributes: [
              ...editedEvent.element.track.attributes.filter(
                (at: any) => at.id !== editedAttribute.id,
              ),
              {
                id: editedAttribute.id,
                name: editedAttribute.name,
                type: editedAttribute.type,
                values: [...editedAttribute.values, { ...value }],
              },
            ],
          },
        };
        newEditedEvents = [
          ...editedEvents.slice(0, editedEvent.index),
          { ...newEditedEvent },
          ...editedEvents.slice(editedEvent.index + 1),
        ];
      }
    } else {
      if (!!baseEventAttribute && !!value) {
        const newAttributeEdited = {
          ...baseEventAttribute,
          values: [{ ...value }],
        };
        newEditedEvent = {
          ...editedEvent.element,
          track: {
            ...editedEvent.element.track,
            attributes: [
              ...editedEvent.element.track.attributes,
              { ...newAttributeEdited },
            ],
          },
        };
        newEditedEvents = [
          ...editedEvents.slice(0, editedEvent.index),
          { ...newEditedEvent },
          ...editedEvents.slice(editedEvent.index + 1),
        ];
      }
    }
    const activeEvent = filterAndIndex(activeEvents, eventId, 'id');
    // TODO Тут TS ругался не просто так, Условия и логика функции прописано не врено
    // newEditedEvent.track.id Тут вполне может не смуществовать.
    // Быстрофикс - прописал временные типы чтоб убрать ошибку, но и только
    const activeTrackFound = filterAndIndex(
      activeEvent.element.tracks,
      newEditedEvent.track.id,
      'id',
    );
    const activeAttribute = filterAndIndex(
      activeTrackFound.element?.attributes || [],
      attributeId,
      'id',
    );
    let newActiveEvent;
    if (activeAttribute.index >= 0) {
      if (activeEvent.index >= 0 && typeof value !== 'undefined') {
        newActiveEvent = {
          ...activeEvent.element,
          tracks: [
            ...activeEvent.element.tracks.slice(0, activeTrackFound.index),
            {
              ...activeTrackFound.element,
              attributes: [
                ...activeTrackFound.element.attributes.slice(
                  0,
                  activeAttribute.index,
                ),
                {
                  ...activeAttribute.element,
                  values: [...activeAttribute.element.values, { ...value }],
                },
                ...activeTrackFound.element.attributes.slice(
                  activeAttribute.index + 1,
                ),
              ],
            },
            ...activeEvent.element.tracks.slice(activeTrackFound.index + 1),
          ],
        };
      }
    } else {
      if (baseEventAttribute) {
        const newAttributeActive = {
          ...baseEventAttribute,
          values: [{ ...value }],
        };
        newActiveEvent = {
          ...activeEvent.element,
          tracks: [
            ...activeEvent.element.tracks.slice(0, activeTrackFound.index),
            {
              ...activeTrackFound.element,
              attributes: [
                ...activeTrackFound.element.attributes,
                { ...newAttributeActive },
                // ...activeTrackFound.element.attributes.slice(activeAttribute.index + 1),
              ],
            },
            ...activeEvent.element.tracks.slice(activeTrackFound.index + 1),
          ],
        };
      }
    }
    const newActiveEvents: Array<ActiveEventType> = [
      ...activeEvents.slice(0, activeEvent.index),
      { ...newActiveEvent },
      ...activeEvents.slice(activeEvent.index + 1),
    ];
    dispatch(ObserveRecordingActualAction(newEditedEvents, newActiveEvents));
  };
};

// USED
export const RemoveEventAttributeValueAction = (
  valueId: number,
  attributeId: number,
  eventId: number,
): ThunkAction<Promise<void>, AppStateType, undefined, RecorderActionTypes> => {
  return async (dispatch, getState) => {
    const editedEvents = [...getState().recorderReducerNew.editedEvents];
    const editedFound = filterAndIndex(editedEvents, eventId, 'id');
    const editedEvent = { ...editedFound.element };
    const editedAttribute: EventAttributeType =
      editedEvent.track.attributes.filter(
        (att: any) => att.id === attributeId,
      )[0];
    const newAtributes = [
      ...editedEvent.track.attributes.filter(
        (att: any) => att.id !== attributeId,
      ),
    ];
    if (editedAttribute?.values.length > 1) {
      const newAttribute = {
        id: editedAttribute.id,
        name: editedAttribute.name,
        type: editedAttribute.type,
        values: [...editedAttribute.values.filter((val) => val.id !== valueId)],
      };
      newAtributes.push(newAttribute);
    }
    const newEditedEvent: EditedEventType = {
      id: editedEvent.id,
      name: editedEvent.name,
      track: {
        id: editedEvent.track.id,
        start: editedEvent.track.start,
        stop: editedEvent.track.stop,
        isRecording: editedEvent.track.isRecording,
        coord_x: editedEvent.track.coord_x,
        coord_y: editedEvent.track.coord_y,
        fast_event: editedEvent.track.fast_event,
        attributes: [...newAtributes],
      },
    };
    const newEditedEvents = [
      ...editedEvents.slice(0, editedFound.index),
      { ...newEditedEvent },
      ...editedEvents.slice(editedFound.index + 1),
    ];

    const activeEvents = getState().recorderReducer.activeEvents;
    const activeEvent = filterAndIndex(activeEvents, eventId, 'id');
    let activeEventTrack;
    let newActiveEvent;
    if (activeEvent.index >= 0) {
      activeEventTrack = filterAndIndex(
        activeEvent.element.tracks,
        newEditedEvent.track.id,
        'id',
      );
    }
    if (
      activeEvent.index >= 0 &&
      !!activeEventTrack &&
      activeEventTrack.index >= 0
    ) {
      const activeEventAttribute = filterAndIndex(
        activeEventTrack.element.attributes,
        attributeId,
        'id',
      );
      if (activeEventAttribute.element.values.length > 1) {
        newActiveEvent = {
          ...activeEvent.element,
          tracks: [
            ...activeEvent.element.tracks.slice(0, activeEventTrack.index),
            {
              ...activeEventTrack.element,
              attributes: [
                ...activeEventTrack.element.attributes.slice(
                  0,
                  activeEventAttribute.index,
                ),
                {
                  ...activeEventAttribute.element,
                  values: [
                    ...activeEventAttribute.element.values.filter(
                      (v: EventAttributeValueType) => v.id !== valueId,
                    ),
                  ],
                },
                ...activeEventTrack.element.attributes.slice(
                  activeEventAttribute.index + 1,
                ),
              ],
            },
            ...activeEvent.element.tracks.slice(activeEventTrack.index + 1),
          ],
        };
      } else {
        newActiveEvent = {
          ...activeEvent.element,
          tracks: [
            ...activeEvent.element.tracks.slice(0, activeEventTrack.index),
            {
              ...activeEventTrack.element,
              attributes: [
                ...activeEventTrack.element.attributes.slice(
                  0,
                  activeEventAttribute.index,
                ),
                ...activeEventTrack.element.attributes.slice(
                  activeEventAttribute.index + 1,
                ),
              ],
            },
            ...activeEvent.element.tracks.slice(activeEventTrack.index + 1),
          ],
        };
      }
    }
    const newActiveEvents: ActiveEventType[] = [
      ...activeEvents.slice(0, activeEvent.index),
      { ...newActiveEvent },
      ...activeEvents.slice(activeEvent.index + 1),
    ];
    dispatch(ObserveRecordingActualAction(newEditedEvents, newActiveEvents));
  };
};

// USED
export const AddNumericValueToAttributeAction = (
  newValue: any,
  attributeId: number,
  eventId: number,
): ThunkAction<Promise<void>, AppStateType, undefined, RecorderActionTypes> => {
  return async () => {
    await API.addNewValue(eventId, attributeId, newValue);
  };
};
// USED
export const GetCurrentEventsAction = (
  taskId: number | undefined,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch) => {
    if (taskId) {
      const response = await API.getCurrentEvents(taskId);
      const events: ActiveEventType[] = response.map((el: ActiveEventType) => {
        el.isVisible = true;
        return el;
      });
      dispatch(baseUpdateActualEventsAction(events));
      dispatch(setActiveEvents(events));
      dispatch(SetPossessionsFromTaskActions(events));
      dispatch(GetPlayers(taskId));
    }
  };
};

export const UpdateEditedTrack = (
  eventId: number,
  trackId: number,
  start: number,
  stop: number,
): ThunkAction<Promise<void>, AppStateType, undefined, RecorderActionTypes> => {
  return async (dispatch, getState) => {
    const activeEvents = [...getState().recorderReducer.activeEvents];
    const activeEvent = filterAndIndex(activeEvents, eventId, 'id');
    const editedEvents: EditedEventType[] = [
      ...getState().recorderReducer.editedEvents,
    ];
    const editedEvent = filterAndIndex(editedEvents, eventId, 'id');
    const activeEventTrack = filterAndIndex(
      activeEvent.element.tracks,
      trackId,
      'id',
    );
    const editedEventTrack = { ...editedEvent.element.track };

    const updatedTrack = {
      ...editedEventTrack,
      start: start,
      stop: stop,
    };

    const newEditedEvents: EditedEventType[] = [
      ...editedEvents.slice(0, editedEvent.index),
      {
        ...editedEvent.element,
        track: { ...updatedTrack },
      },
      ...editedEvents.slice(editedEvent.index + 1),
    ];

    const newActiveEvents = [
      ...activeEvents.slice(0, activeEvent.index),
      {
        ...activeEvent.element,
        tracks: [
          ...activeEvent.element.tracks.slice(0, activeEventTrack.index),
          { ...updatedTrack },
          ...activeEvent.element.tracks.slice(activeEventTrack.index + 1),
        ],
      },
      ...activeEvents.slice(activeEvent.index + 1),
    ];
    dispatch(ObserveRecordingActualAction(newEditedEvents, newActiveEvents));
  };
};

type SetPlayedFrameType = {
  type: typeof SET_PLAYED_FRAME;
  payload: any;
};
export const SetPlayedFrameAction = (frame: any): SetPlayedFrameType => {
  return {
    type: SET_PLAYED_FRAME,
    payload: frame,
  };
};
type EventWindowModeType = {
  type: typeof EVENT_WINDOW_MODE;
  payload: boolean;
};
export const SetEventWindowModeAction = (
  mode: boolean,
): EventWindowModeType => {
  return {
    type: EVENT_WINDOW_MODE,
    payload: mode,
  };
};
type SetEventCoordinatesActionType = {
  type: typeof SET_EVENT_COORDINATES_ACTION;
  payload: any;
};
// eslint-disable-next-line @typescript-eslint/naming-convention
export const AC_SetEventCoordinatesAction = (
  payload: any,
): SetEventCoordinatesActionType => {
  return {
    type: SET_EVENT_COORDINATES_ACTION,
    payload: payload,
  };
};
export const SetEventCoordinatesAction = (
  eventId: number,
  trackId: number,
  coord_x: number | null,
  coord_y: number | null,
): ThunkAction<Promise<void>, AppStateType, undefined, RecorderActionTypes> => {
  return async (dispatch, getState) => {
    const activeEvents = [...getState().recorderReducer.activeEvents];
    const activeEvent = filterAndIndex(activeEvents, eventId, 'id');
    const editedEvents: EditedEventType[] = [
      ...getState().recorderReducer.editedEvents,
    ];
    const editedEvent = filterAndIndex(editedEvents, eventId, 'id');
    const activeEventTrack = filterAndIndex(
      activeEvent.element.tracks,
      trackId,
      'id',
    );
    const editedEventTrack = { ...editedEvent.element.track };

    const updatedTrack = {
      ...editedEventTrack,
      coord_x: coord_x,
      coord_y: coord_y,
    };

    const newEditedEvents: EditedEventType[] = [
      ...editedEvents.slice(0, editedEvent.index),
      {
        ...editedEvent.element,
        track: { ...updatedTrack },
      },
      ...editedEvents.slice(editedEvent.index + 1),
    ];

    const newActiveEvents = [
      ...activeEvents.slice(0, activeEvent.index),
      {
        ...activeEvent.element,
        tracks: [
          ...activeEvent.element.tracks.slice(0, activeEventTrack.index),
          { ...updatedTrack },
          ...activeEvent.element.tracks.slice(activeEventTrack.index + 1),
        ],
      },
      ...activeEvents.slice(activeEvent.index + 1),
    ];

    dispatch(ObserveRecordingActualAction(newEditedEvents, newActiveEvents));
  };
};
export const AddNewPeriodEvent = (
  periodAttribute: any,
  teamAttribute: any,
  teamType: string,
  start: number,
  stop: number,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch, getState) => {
    try {
      const attributes = [
        {
          id: periodAttribute.attribute_id,
          values: [{ value: periodAttribute.value, id: periodAttribute.id }],
        },
        {
          id: teamAttribute.attribute_id,
          values: [{ value: teamType, id: teamAttribute.id }],
        },
      ];
      const newPeriodTrack = {
        id: null,
        start: start,
        stop: stop,
        isRecrding: false,
        coord_x: null,
        coord_y: null,
        attributes: attributes,
        fast_event: null,
      };
      const activeTask = getState().taskReducer.activeTask;
      const periodEvent = activeTask?.periods ? activeTask.periods[0] : null;
      if (periodEvent) {
        const response = await API.saveNewEvent(
          newPeriodTrack.start,
          newPeriodTrack.stop,
          attributes,
          periodEvent.event_id,
          activeTask?.id,
          null,
          null,
          null,
        );
        newPeriodTrack.id = response.created_event.id;
        dispatch(
          pushActiveEventAction({
            eventId: periodEvent.event_id,
            track: newPeriodTrack,
            event: {
              id: periodEvent.event_id,
              name: 'Period',
              type: 'game',
              isVisible: true,
              unique_attributes: true,
              tracks: [newPeriodTrack],
            },
          }),
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
};
const { removeFastEventAction, setShowCoordinatesModal } =
  fastEventReducer.actions;
export const SetPlayerActionToEdit = (
  playerNumber: string,
  teamType: string,
  playedSeconds?: number | undefined,
  isFirstPlayer?: boolean | undefined, // Фикс, используется только для игнора клика не по тому колобку на поле при замене
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch, getState) => {
    const { currentFastEvent } = getState().fastEventReducer;
    const {
      event_id,
      same_team = false,
      participants = 0,
      additional_values,
      player_skip = false,
      key,
      has_coords = false,
    } = currentFastEvent;
    const isDuel = key == 'duel';
    const editedEvent = [...getState().recorderReducerNew.editedEvents].filter(
      (evt: any) => evt.id === event_id,
    )[0];
    try {
      if (participants === 1) {
        const attrValue = additional_values.filter(
          (addV: any) => addV.key === 'team' && addV.value === teamType,
        )[0];
        const playerAttrValue = additional_values.filter(
          (addV: any) => addV.key === 'player',
        )[0];
        const playerValue = [
          {
            id: playerAttrValue.attribute_id,
            name: 'Player',
            type: 'NUMBER',
            key: 'player',
            values: [
              {
                id: playerAttrValue.id,
                value: playerNumber.toString(),
              },
            ],
          },
          {
            id: attrValue.attribute_id,
            name: 'Team',
            type: 'TEXT',
            key: 'team',
            values: [
              {
                id: attrValue.id,
                value: teamType,
              },
            ],
          },
        ];
        await dispatch(
          setPlayerActionToEdit({
            attributes: playerValue,
            eventId: event_id,
          }),
        );
        return;
        // console.log(
        //   playerValue.length,
        //   editedEvent.track.attributes.length,
        //   has_coords,
        // );
      }
      if (participants === 2) {
        if (isDuel) {
          const existedPlayers = editedEvent.track.attributes.filter(
            (attr: any) =>
              attr.name === 'Player home' || attr.name === 'Player away',
          );
          // TODO need team????
          const attributesForAdding = [];
          const existedTeam = editedEvent.track.attributes.filter(
            (attr: any) => attr.name === 'Team',
          );
          if (existedTeam.length == 0) {
            const { id, attribute_id } = additional_values.filter(
              (addV: any) =>
                addV.key === 'team' &&
                addV.value.toLowerCase() === teamType.toLowerCase(),
            )[0];
            attributesForAdding.push({
              id: attribute_id,
              name: 'Team',
              type: 'TEXT',
              key: 'team',
              values: [
                {
                  id: id,
                  value: teamType,
                  is_default: false,
                },
              ],
            });
          }

          // TODO switch team
          // if (existedTeam.length == 1) {
          //   console.log('use existedTeam attr || replace existedTeam attr');
          // }
          if (existedPlayers.length == 0) {
            const { id, attribute_id } = additional_values.filter(
              (addV: any) => addV.key === `player_${teamType.toLowerCase()}`,
            )[0];
            attributesForAdding.push({
              id: attribute_id,
              name: `Player ${teamType.toLowerCase()}`,
              type: 'NUMBER',
              key: `player_${teamType.toLowerCase()}`,
              values: [
                {
                  id: id,
                  value: playerNumber.toString(),
                },
              ],
            });
          }
          if (existedPlayers.length == 1) {
            const { id, attribute_id } = additional_values.filter(
              (addV: any) => addV.key === `player_${teamType.toLowerCase()}`,
            )[0];
            // TODO check for duplicate
            attributesForAdding.push({
              id: attribute_id,
              name: `Player ${teamType.toLowerCase()}`,
              type: 'NUMBER',
              key: 'player',
              values: [
                {
                  id: id,
                  value: playerNumber.toString(),
                },
              ],
            });
          }
          await dispatch(
            setPlayerActionToEdit({
              attributes: attributesForAdding,
              eventId: event_id,
            }),
          );
          const hasCoords =
            editedEvent?.track?.coord_x &&
            editedEvent?.track?.coord_y &&
            has_coords;
          if (
            (attributesForAdding.length +
              editedEvent.track.attributes.length ===
              4 &&
              hasCoords) ||
            !has_coords
          ) {
            await dispatch(RemoveEditedEventAction(editedEvent, playedSeconds));
            await dispatch(removeFastEventAction());
            await dispatch(SetIsPlaying(true));
            await dispatch(setShowCoordinatesModal(false));
          }
          return;
        }

        if (player_skip) {
          const existedPlayers = editedEvent.track.attributes.filter(
            (attr: any) =>
              attr.key === 'player' || attr.key === 'player_opposite',
          );
          const existedTeam = editedEvent.track.attributes.filter(
            (attr: any) => attr.name === 'Team',
          );
          const attributesForAdding = [];
          if (existedTeam.length == 0) {
            const { id, attribute_id } = additional_values.filter(
              (addV: any) =>
                addV.key === 'team' &&
                addV.value.toLowerCase() === teamType.toLowerCase(),
            )[0];
            attributesForAdding.push({
              id: attribute_id,
              name: 'Team',
              type: 'TEXT',
              key: 'team',
              values: [
                {
                  id: id,
                  value: teamType,
                  is_default: false,
                },
              ],
            });
          }
          // TODO switch team
          // if (existedTeam.length == 1) {
          //   console.log('use existedTeam attr || replace existedTeam attr?');
          // }
          if (existedPlayers.length == 0) {
            const { id, attribute_id } = additional_values.filter(
              (addV: any) => addV.key === 'player',
            )[0];
            attributesForAdding.push({
              id: attribute_id,
              name: 'Player',
              type: 'NUMBER',
              key: 'player',
              values: [
                {
                  id: id,
                  value: playerNumber.toString(),
                  is_default: false,
                },
              ],
            });
          }
          if (existedPlayers.length == 1) {
            const { id, attribute_id } = additional_values.filter(
              (addV: any) => addV.key === 'player_opposite',
            )[0];
            attributesForAdding.push({
              id: attribute_id,
              name: 'Player opposite',
              key: 'player_opposite',
              type: 'NUMBER',
              values: [
                {
                  id: id,
                  value: playerNumber.toString(),
                  is_default: false,
                },
              ],
            });
          }
          // if (existedPlayers.length == 2) {
          //   console.log('replace some player?');
          // }
          // console.log(existedPlayers, existedTeam);
          await dispatch(
            setPlayerActionToEdit({
              attributes: attributesForAdding,
              eventId: event_id,
            }),
          );
          const hasCoords =
            editedEvent?.track?.coord_x &&
            editedEvent?.track?.coord_y &&
            has_coords;
          if (
            (attributesForAdding.length +
              editedEvent.track.attributes.length ===
              4 &&
              hasCoords) ||
            !has_coords
          ) {
            await dispatch(
              RemoveEditedEventAction(Number(event_id), playedSeconds),
            );
            await dispatch(removeFastEventAction());
            await dispatch(SetIsPlaying(true));
            await dispatch(setShowCoordinatesModal(false));
          }
          return;
        }

        if (same_team) {
          // same_team - required
          const existedPlayers = editedEvent.track.attributes.filter(
            (attr: any) =>
              attr.name === 'Player in' || attr.name === 'Player out',
          );
          const attributesForAdding = [];
          if (existedPlayers.length == 0) {
            const { id, attribute_id } = additional_values.filter(
              (addV: any) => addV.key === 'player_out',
            )[0];
            attributesForAdding.push({
              id: attribute_id,
              name: 'Player out',
              type: 'NUMBER',
              key: 'player_out',
              values: [
                {
                  id: id,
                  value: playerNumber.toString(),
                  is_default: false,
                },
              ],
            });
          }
          if (existedPlayers.length == 1) {
            if (isFirstPlayer) {
              console.error('wrong player');
              return;
            }
            const { id, attribute_id } = additional_values.filter(
              (addV: any) => addV.key === 'player_in',
            )[0];
            attributesForAdding.push({
              id: attribute_id,
              name: 'Player in',
              type: 'NUMBER',
              key: 'player_in',
              values: [
                {
                  id: id,
                  value: playerNumber.toString(),
                  is_default: false,
                },
              ],
            });
            console.log(attributesForAdding);
          }
          // TODO switch players
          // if (existedPlayers.length == 2) {
          //   console.log('replace some player?');
          // }
          const existedTeam = editedEvent.track.attributes.filter(
            (attr: any) => attr.name === 'Team',
          );
          if (existedTeam.length == 0) {
            const { id, attribute_id } = additional_values.filter(
              (addV: any) => addV.key === 'team' && addV.value === teamType,
            )[0];
            attributesForAdding.push({
              id: attribute_id,
              name: 'Team',
              type: 'TEXT',
              key: 'team',
              values: [
                {
                  id: id,
                  value: teamType,
                  is_default: false,
                },
              ],
            });
          }
          await dispatch(
            setPlayerActionToEdit({
              attributes: attributesForAdding,
              eventId: event_id,
            }),
          );
          // const hasCoords =
          //   editedEvent?.track?.coord_x &&
          //   editedEvent?.track?.coord_y &&
          //   has_coords;
          // TODO может эжто условие?
          // if (
          //   (attributesForAdding.length +
          //     editedEvent.track.attributes.length ===
          //     4 &&
          //     hasCoords) ||
          //   !has_coords
          // ) {
          //   await dispatch(
          //     RemoveEditedEventAction(Number(event_id), playedSeconds),
          //   );
          //   await dispatch(removeFastEventAction());
          //   await dispatch(SetIsPlaying(true));
          //   await dispatch(setShowCoordinatesModal(false));
          // }
          // return;
          if (
            attributesForAdding.length + editedEvent.track.attributes.length ===
            3
          ) {
            // считаем, что если 3 атрибута есть (2 игрока и указатель на команду),
            // то событие полностью заполнено и его можно
            // записывать
            console.log('record event');
            dispatch(RemoveEditedEventAction(currentFastEvent, playedSeconds));
            dispatch(removeFastEventAction());
            dispatch(SetIsPlaying(true));
          }
          return;
        }

        if (!isDuel && !player_skip && !same_team) {
          const existedPlayers = editedEvent.track.attributes.filter(
            (attr: any) =>
              attr.key === 'player' || attr.key === 'player_opposite',
          );
          const existedTeam = editedEvent.track.attributes.filter(
            (attr: any) => attr.name === 'Team',
          );
          const attributesForAdding = [];
          if (existedTeam.length == 0) {
            const { id, attribute_id } = additional_values.filter(
              (addV: any) => addV.key === 'team' && addV.value === teamType,
            )[0];
            attributesForAdding.push({
              id: attribute_id,
              name: 'Team',
              type: 'TEXT',
              key: 'team',
              values: [
                {
                  id: id,
                  value: teamType,
                  is_default: false,
                },
              ],
            });
          }
          // TODO switch team?
          // if (existedTeam.length == 1) {
          //   console.log('use existedTeam attr || replace existedTeam attr?');
          // }
          if (existedPlayers.length == 0) {
            const { id, attribute_id } = additional_values.filter(
              (addV: any) => addV.key === 'player',
            )[0];
            attributesForAdding.push({
              id: attribute_id,
              name: 'Player',
              type: 'NUMBER',
              key: 'player',
              values: [
                {
                  id: id,
                  value: playerNumber.toString(),
                  is_default: false,
                },
              ],
            });
          }
          if (existedPlayers.length == 1) {
            const { id, attribute_id } = additional_values.filter(
              (addV: any) => addV.key === 'player_opposite',
            )[0];
            attributesForAdding.push({
              id: attribute_id,
              name: 'Player opposite',
              key: 'player_opposite',
              type: 'NUMBER',
              values: [
                {
                  id: id,
                  value: playerNumber.toString(),
                  is_default: false,
                },
              ],
            });
          }
          // TODO switch players
          // if (existedPlayers.length == 2) {
          //   console.log('replace some player?');
          // }
          await dispatch(
            setPlayerActionToEdit({
              attributes: attributesForAdding,
              eventId: event_id,
            }),
          );
          const hasCoords =
            editedEvent?.track?.coord_x &&
            editedEvent?.track?.coord_y &&
            has_coords;
          if (
            (attributesForAdding.length +
              editedEvent.track.attributes.length ===
              4 &&
              hasCoords) ||
            !has_coords
          ) {
            // считаем, что если 4 атрибута есть (2 игрока и указатель на 2 команды),
            // и есть координаты
            // то событие полностью заполнено и его можно
            // записывать
            await dispatch(
              RemoveEditedEventAction(Number(event_id), playedSeconds),
            );
            await dispatch(removeFastEventAction());
            await dispatch(SetIsPlaying(true));
            await dispatch(setShowCoordinatesModal(false));
          }
          return;
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
};
