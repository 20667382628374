import React, { useEffect, useRef, useState } from 'react';

import { Flex, Select, Tooltip } from 'antd';

import {
  getGamesListAPI,
  getPlacementsByGameAPI,
  getPlacementSchemas,
} from 'api';
import { FIELD_IMAGES, filePath } from 'constants/constants';

const PlacementFixerLayout = () => {
  const [games, setGames] = useState<any[]>([]);
  const [teams, setTeams] = useState([]);
  const [schemas, setSchemas] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedPlacement, setSelectedPlacement] = useState<any>(null);
  // eslint-disable-next-line
  const [alteredPlacement, setAlteredPlacement] = useState<any>(null);
  const [placements, setPlacements] = useState<any[]>([]);
  useEffect(() => {
    const updatedPlacement: any = [];
    // console.log('placements');
    // console.log(placements);
    const sPl = placements.filter((pp) => pp.value === selectedPlacement)[0];
    const schemaName = sPl?.label?.split(' - ')[0];
    const schema: any = schemas.filter((s: any) => s.name === schemaName)[0];
    console.log('sPl');
    console.log(sPl?.content);
    schema?.positions.forEach((position: any) => {
      // console.log(position.id);
      if (position.coordY >= 0.45 && position.coordY <= 0.55) {
        const p0 = sPl.content.filter((p: any) => p.id === position.id)[0];
        if (p0) {
          updatedPlacement.push(p0);
          console.log('pushed unchanged');
        }
      } else {
        const mirrorredPosition = schema?.positions.filter(
          (mp: any) =>
            mp.coordX === position.coordX &&
            mp.id !== position.id &&
            (Math.abs(1 - mp.coordY) === position.coordY ||
              Math.abs(1 - position.coordY) === mp.coordY),
        )[0];
        const p0 = sPl.content.filter((p: any) => p.id === position.id)[0];
        if (mirrorredPosition) {
          console.log(
            `mirrorredPosition ${mirrorredPosition.id} ${position.id}`,
          );
          if (
            updatedPlacement.filter((up: any) => up.id === mirrorredPosition.id)
              .length === 0
          ) {
            const p1 = sPl.content.filter((p: any) => p.id === position.id)[0];
            const mirrorredPosition1 = sPl.content.filter(
              (p: any) => p.id === mirrorredPosition.id,
            )[0];
            if (mirrorredPosition1) {
              updatedPlacement.push({
                ...position,
                player_id: mirrorredPosition1?.player_id,
                player_number: mirrorredPosition1?.player_number,
              });
            }
            if (p1) {
              updatedPlacement.push({
                ...mirrorredPosition,
                player_id: p1?.player_id,
                player_number: p1?.player_number,
              });
            }
          } else {
            console.log('already pushed');
          }
        } else {
          console.log('no mirror');
          updatedPlacement.push(p0);
        }
      }
    });
    console.log('alteredPlacement');
    console.log(updatedPlacement);
    setAlteredPlacement(updatedPlacement);
  }, [selectedPlacement]);
  console.log(schemas);
  useEffect(() => {
    getGamesListAPI().then((response) => {
      setGames(response);
      getPlacementSchemas('1').then((response1) => {
        setSchemas(response1);
      });
    });
  }, []);
  useEffect(() => {
    if (selectedGame) {
      const t = games.filter((game: any) => game.value === selectedGame)[0]
        .teams;
      setTeams(t);
      setSelectedPlacement(null);
      setSelectedTeam(null);
    }
  }, [selectedGame]);
  useEffect(() => {
    if (selectedGame && selectedTeam) {
      getPlacementsByGameAPI(selectedGame, selectedTeam).then((response) => {
        setPlacements(response);
      });
    }
  }, [selectedTeam]);
  const containerRefLeft = useRef(null);
  const containerRefRight = useRef(null);
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Select
        style={{ width: 500 }}
        options={games}
        showSearch
        onSelect={setSelectedGame}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
      />
      <Select
        style={{ width: 500 }}
        options={teams}
        onSelect={setSelectedTeam}
      />
      <Select
        style={{ width: 500 }}
        options={placements}
        onSelect={setSelectedPlacement}
      />
      <Flex gap={'2rem'}>
        <div style={{ position: 'relative' }} ref={containerRefLeft}>
          <div>Изначальная расстановка</div>
          <img
            src={`${filePath}/files/fields/${FIELD_IMAGES[1][0].value}`}
            alt=""
          />
          {placements
            .filter((p: any) => p.value === selectedPlacement)[0]
            ?.content?.map((position: any) => (
              <Tooltip title={position.label}>
                <div
                  style={{
                    position: 'absolute',
                    width: 20,
                    margin: 'auto',
                    textAlign: 'center',
                    height: 20,
                    borderRadius: '50%',
                    left: `${position.coordX * 100}%`,
                    top: `${position.coordY * 100}%`,
                    backgroundColor: 'green',
                  }}
                >
                  {position.player_number}
                </div>
              </Tooltip>
            ))}
        </div>
        <div style={{ position: 'relative' }} ref={containerRefRight}>
          <div>Результат инверсии</div>
          <img
            src={`${filePath}/files/fields/${FIELD_IMAGES[1][0].value}`}
            alt=""
          />
          {alteredPlacement?.map((position: any) => (
            <Tooltip title={position.label}>
              <div
                style={{
                  position: 'absolute',
                  width: 20,
                  margin: 'auto',
                  textAlign: 'center',
                  height: 20,
                  borderRadius: '50%',
                  left: `${position.coordX * 100}%`,
                  top: `${position.coordY * 100}%`,
                  backgroundColor: 'green',
                }}
              >
                {position.player_number}
              </div>
            </Tooltip>
          ))}
        </div>
      </Flex>
    </div>
  );
};
export default PlacementFixerLayout;
