import { endpoint } from 'constants/constants';
import myAxios from 'utils/axios';

import { createCommonHeader } from './_functions';

export async function getGamesListAPI() {
  const url = `${endpoint}/api/v1/games/`;
  const headers = createCommonHeader();
  const response = await myAxios.GET(url, null, headers);
  return response.data;
}

export async function getPlacementsByGameAPI(gameId: string, teamId: string) {
  const url = `${endpoint}/api/v1/game-placements/?gameId=${gameId}&teamId=${teamId}`;
  const headers = createCommonHeader();
  const response = await myAxios.GET(url, null, headers);
  return response.data;
}
